import Marquee from "react-fast-marquee";
import gif from "../../assets/events/event2.gif";

const Header = () => {
  return (
    <section
      style={{ backgroundImage: `url(${gif})` }}
      className="relative bg-cover bg-no-repeat bg-center h-[100vh] px-[40px]"
    >
      <section
        className="h-[80%] lg:h-[75%] pt-[40px] lg:pt-[80px] 3xl:pt-[150px]
                  mobile-landscape:h-[70%]"
      >
        <div
          className="mt-8 lg:mt-[55px] header-text
                    max-w-[850px] mx-auto text-formisWhitef5 text-center
                    mobile-landscape:text-[20px] mobile-landscape:mt-0"
        >
          <h1 className="mobile-landscape:leading-[30px]">
            Catch the Vibe, <br className="block md:hidden" /> Ride the Wave.
          </h1>
        </div>

        <div
          className="font-light text-formisWhitef5 max-w-[850px] text-justify mx-auto 
                      mt-7 mobile-landscape:mt-2"
        >
          <p className="text-[12px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[25px] text-white opacity-60 font-[200]">
            Get ready to dive into{" "}
            <span className="font-bold rumble-brave tracking-wider">HYDROVIBE 2024</span>, the splashiest,
            most electrifying festival of the year! Picture this: jaw-dropping{" "}
            <span className="font-bold">wave pool stunts</span>, epic{" "}
            <span className="font-bold">light and water shows</span>, and a
            storm of <span className="font-bold">DJ beats</span> that'll keep
            you vibing all day long. There’s live music, hilarious stand-up
            acts, spontaneous <span className="font-bold">flash mobs</span>, and
            all the splash vibes you could ask for. Hungry? Thirsty? We’ve got
            you covered with{" "}
            <span className="font-bold">food, drinks, and a flea market</span>{" "}
            to keep the energy high. It’s going to be a{" "}
            <span className="font-bold">wave of fun !</span> 🌊🎶
          </p>
        </div>

        <div className="flex justify-center gap-8 mt-8 md:mt-10">
          <div className="flex flex-col md:flex-row gap-8">
            <button
              // onClick={() => setIsModalOpen(true)}
              className="text-[#f1edeb] text-[14px] border border-[#f1edeb] h-[40px] rounded-full w-[110px] md:w-[150px]"
            >
              ELITE OASIS
            </button>
            <button className="text-[#f1edeb] text-[14px] border border-[#f1edeb] h-[40px] rounded-full w-[110px] md:w-[150px]">
              SUMMIT SEATS
            </button>
          </div>
          <div className="flex flex-col md:flex-row gap-8">
            <button className="text-[#f1edeb] text-[14px] border border-[#f1edeb] h-[40px] rounded-full w-[110px] md:w-[150px]">
              DUNE DECK
            </button>
            <button className="text-[#f1edeb] text-[14px] border border-[#f1edeb] h-[40px] rounded-full w-[110px] md:w-[150px]">
              DESERT PASS
            </button>
          </div>
        </div>
      </section>

      <section
        className="h-[20%] lg:h-[25%] px-[20px] md:mx-[40px] lg:mx-[60px] 2xl:mx-[90px]
                  mobile-landscape:h-[30%]"
      >
        <Marquee speed={200} className="marquee-container">
          <p className="text-white text-[30px] opacity-60">
            FREE FOR <s>FASTPASS</s> | KITSA™ USERS, KNOWN AS FORMISABLES. IT'S
            OUR WAY OF SAYING THANKS
          </p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">
            FREE FOR <s>FASTPASS</s> | KITSA™ USERS, KNOWN AS FORMISABLES. IT'S
            OUR WAY OF SAYING THANKS
          </p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Marquee>

        <Marquee speed={150} className="marquee-container" direction="right">
          <p className="text-white text-[30px] opacity-60">HYDROVIBE 2024</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">ELITE OASIS</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">SUMMIT SEATS</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">DUNE DECK</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">DESERT PASS</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">HYDROVIBE 2024</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">ELITE OASIS</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">SUMMIT SEATS</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">DUNE DECK</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="text-white text-[30px] opacity-60">DESERT PASS</p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Marquee>
      </section>
    </section>
  );
};

export default Header;
