import LineupAccordian from "./LineupAccordian";
import img from "../../assets/img2.jpg";
import img1 from "../../assets/events/img1.png";
import stage from "../../assets/events/stage.png";
import { FaArrowUp } from "react-icons/fa6";

const Lineup = () => {
  return (
    <div>
      <section
        style={{ backgroundImage: `url(${img1})` }}
        className="relative bg-center bg-cover bg-no-repeat text-white py-[80px] lg:py-[120px]"
      >
        <div
          className="bg-gradient-to-b from-formisBlack15/50 to-formisBlack15 backdrop-blur-sm
                        absolute top-0 left-0 z-0 opacity-90 w-full h-full"
        ></div>

        <section className="relative z-10 flex w-screen justify-between">
          <div className="md:border-r border-dashed md:w-[65%]">
            <div
              className="ml-[40px] lg:ml-[60px] xl:ml-[90px] md:max-w-[75%]
                          mr-[40px] md:mr-0"
            >
              <h4 className="text-[16px] lg:text-[20px] mb-3 lg:mb-5">
                A KITSA<span className="text-[50%] align-super">™</span>.LIFE
                CONCERT
              </h4>
              <h1 className="header-text mb-3 lg:mb-5 rumble-brave tracking-wider">HYDROVIBE 2024</h1>
              <p className="text-[18px] lg:text-[24px] mb-3 lg:mb-5">
                ONE NIGHT | ONE VIBE -{" "}
                <span className="font-bold inline-block">BE UNSTOPPABLE.</span>
              </p>
            </div>

            {/* BANNER */}
            <div
              className="hidden lg:flex w-full items-center justify-between bg-[#cebeb8] 
                          h-[80px] lg:max-w-[90%] text-[16px] xl:text-[20px] 2xl:text-[24px] text-formisBlack15 font-bold"
            >
              <span className=" pl-10">
                <a
                  href="https://maps.app.goo.gl/LrEJYoRapJtKMpBK9"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-2"
                >
                  BACKYARD SPORTS CLUB | GURUGRAM
                  <FaArrowUp className="rotate-45" />
                </a>
              </span>
              <span className="">|</span>
              <span className=" pr-10">Saturday, November 9, 2024.</span>
            </div>

            {/* MOBILE BANNERS */}
            <div
              className="lg:hidden w-full flex items-center justify-between bg-[#cebeb8] mt-5
                          h-[60px] max-w-[90%] md:max-w-[75%] text-[12px] md:text-[16px] xl:text-[20px] 2xl:text-[24px] text-formisBlack15 font-bold"
            >
              <span className="pl-10 pr-10">
                <a
                  href="https://maps.app.goo.gl/LrEJYoRapJtKMpBK9"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-2"
                >
                  BACKYARD SPORTS CLUB | GURUGRAM
                  <FaArrowUp className="rotate-45" />
                </a>
              </span>
            </div>
            <div
              className="lg:hidden w-full flex items-center justify-between bg-[#cebeb8] mt-5
                          h-[60px] max-w-[90%] md:max-w-[75%] text-[13px] md:text-[16px] xl:text-[20px] 2xl:text-[24px] text-formisBlack15 font-bold"
            >
              <span className="pl-10 pr-10">Saturday, November 9, 2024.</span>
            </div>
          </div>

          <div className="hidden md:flex items-center justify-center">
            <div
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById(`hydrovibe-hero`)
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
              className="cursor-pointer w-[30%] flex flex-col justify-center items-center mr-[40px] lg:mr-[60px] xl:mr-[90px]"
            >
              <p
                className="flex items-center justify-center text-[28px] font-bold 
                            border-4 border-white rounded-full md:scale-75 lg:scale-100 size-60 mt-5"
              >
                Get Yours Now!
              </p>
            </div>
          </div>
        </section>
      </section>

      {/* <section className="mt-20 mx-[40px] lg:mx-[60px] xl:mx-[90px]">
        <LineupAccordian title="Justin Bieber">
          <img src={img} alt="image" />
        </LineupAccordian>
      </section> */}

      <section className="mx-[40px] lg:mx-[60px] xl:mx-[90px] mt-10 md:mt-20">
        <div className="flex justify-center gap-8 mt-8 md:mt-10 mb-10">
          <div className="flex flex-col md:flex-row gap-8">
            <div className="text-[#f1edeb] text-[14px] md:text-[18px]">
              <span className="flex items-center justify-center text-center">
                (VIP)
              </span>
              <div
                className=" border border-[#f1edeb] w-[130px] md:w-[150px]
                            flex flex-col items-center justify-center h-[70px]"
              >
                <p>ELITE OASIS</p>
                <p>₹3299/-</p>
              </div>
            </div>

            <div className="text-[#f1edeb] text-[14px] md:text-[18px]">
              <span className="flex items-center justify-center text-center">
                (GOLD)
              </span>
              <div
                className=" border border-[#f1edeb] w-[130px] md:w-[150px]
                            flex flex-col items-center justify-center h-[70px]"
              >
                <p>SUMMIT SEATS</p>
                <p>₹2399/-</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-8">
            <div className="text-[#f1edeb] text-[14px] md:text-[18px]">
              <span className="flex items-center justify-center text-center">
                (PLATINUM)
              </span>
              <div
                className=" border border-[#f1edeb] w-[130px] md:w-[150px]
                            flex flex-col items-center justify-center h-[70px]"
              >
                <p>DUNE DECK</p>
                <p>₹1799/-</p>
              </div>
            </div>
            <div className="text-[#f1edeb] text-[14px] md:text-[18px]">
              <span className="flex items-center justify-center text-center">
                (BRONZE)
              </span>
              <div
                className=" border border-[#f1edeb] w-[130px] md:w-[150px]
                            flex flex-col items-center justify-center h-[70px]"
              >
                <p>DESERT PASS</p>
                <p>₹799/-</p>
              </div>
            </div>
          </div>
        </div>
        <img src={stage} alt="Stage" className="mx-auto" />
      </section>

      <section className="flex flex-col md:flex-row my-20 md:my-24">
        <div className="mx-[40px] lg:mx-[60px] xl:mx-[90px] text-white ">
          <h4 className="text-[16px] lg:text-[20px] mb-3 lg:mb-5">
            TICKETS ARE LIVE UNTIL NOVEMBER 5, 2024.
          </h4>
          <h1 className="text-[32px] md:text-[44px] lg:text-[64px] mb-3 lg:mb-5 rumble-brave tracking-wider">
            HYDROVIBE 2024
          </h1>
          <p className="subheader-text mb-3 lg:mb-5 max-w-[80%] md:max-w-[60%] lg:max-w-[50%]">
            <span className="font-bold">Be There, Feel the Vibe!</span> Don’t
            miss your chance to experience HYDROVIBE 2024 with epic DJ, Live
            Bands, Live Performances stunning water spectacles, and more!
          </p>
        </div>

        <a
          onClick={(e) => {
            e.preventDefault();
            document
              .getElementById(`hydrovibe-hero`)
              ?.scrollIntoView({ behavior: "smooth" });
          }}
          className={`cursor-pointer hidden md:block text-white place-self-start`}
        >
          <div className="flex items-center justify-center text-center">
            <div className="w-[30%] flex flex-col justify-center items-center">
              <p
                className="flex items-center justify-center text-[24px] font-bold lg:mr-[60px] xl:mr-[90px]
                            border-4 border-white rounded-full scale-[0.65] md:scale-75 lg:scale-100 size-60 mt-5"
              >
                Get Your Tickets!
              </p>
            </div>
          </div>
        </a>

        <div
          onClick={(e) => {
            e.preventDefault();
            document
              .getElementById(`hydrovibe-hero`)
              ?.scrollIntoView({ behavior: "smooth" });
          }}
          className="md:hidden w-full flex items-center justify-between bg-[#cebeb8] mt-10
                          h-[60px] max-w-[80%] md:max-w-[75%] text-[16px] text-formisBlack15 font-bold"
        >
          <span className="pl-14 pr-10">Get Your Tickets Now!</span>
        </div>
      </section>
    </div>
  );
};

export default Lineup;
