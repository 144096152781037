import { useEffect, useState } from "react";
import Navbar from "../components/Main/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import blacklogo from "../assets/KITSABlack.png";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Ticket = () => {
  const [userData, setUserData] = useState();
  const [qr, setQr] = useState();

  const verifyBookingData = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kitsa.asia/event-registration/user-event-booking",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setUserData(data.data);
        setQr(data.qr);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    verifyBookingData();
  }, []);

  const downloadPDF = () => {
    const ticket = document.getElementById("ticket");

    html2canvas(ticket).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width, canvas.height],
      });

      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
      pdf.save("ticket.pdf");
    });
  };

  return (
    <div>
      <Navbar isLoaded={true} />

      <section className="pt-[100px] text-white mx-[20px]">
        {userData && (
          <section
            id="ticket"
            className="bg-white text-formisBlack15 w-fit mx-auto rounded-[10px] p-4 md:p-10"
          >
            <section>
              <img
                src={blacklogo}
                alt="KITSA"
                className="max-w-[102px] md:max-w-[132px]"
              />
              <h1 className="text-[20px] md:text-[24px] font-bold mb-5">
                HYDROVIBE 2024
              </h1>
              <div className="grid grid-cols-2 gap-2 md:gap-5 mb-5">
                <div>
                  <h4 className="font-bold text-[16px] md:text-[18px]">Date</h4>
                  <p className="text-[14px] md:text-[16px]">9 November, 2024</p>
                </div>
                <div>
                  <h4 className="font-bold text-[16px] md:text-[18px]">Name</h4>
                  <p className="text-[14px] md:text-[16px]">
                    {userData.name || "Formisable"}
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-[16px] md:text-[18px]">
                    Ticket Type
                  </h4>
                  <p className="text-[14px] md:text-[16px]">
                    {userData && userData.ticket}
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-[16px] md:text-[18px]">
                    Ticket Quantity
                  </h4>
                  <p className="text-[14px] md:text-[16px]">
                    {userData && userData.ticket_quantity}
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-[16px] md:text-[18px]">
                    Attending Time
                  </h4>
                  <p className="text-[14px] md:text-[16px]">
                    {userData && userData.attending_time}
                  </p>
                </div>
                <div>
                  <h4 className="font-bold text-[16px] md:text-[18px]">
                    Mobile Number
                  </h4>
                  <p className="text-[14px] md:text-[16px]">
                    {userData && userData.user}
                  </p>
                </div>
              </div>

              <h4 className="font-bold text-[16px] md:text-[18px]">Place</h4>
              <p className="text-[14px] md:text-[16px]">
                Backyard Sports Club, sector 59, Gurugram, Haryana
              </p>
            </section>

            {userData && (userData.payment_completed) ? (
              <section>
                {qr && (
                  <img
                    src={`data:image/png;base64,${qr}`}
                    alt="QR Code"
                    className="mt-5 max-w-72 md:max-w-80"
                  />
                )}
                <div className="text-center text-xs">
                  <p>
                    Please have this ticket handy as this is your{" "}
                    <span className="font-bold">Gate Pass.</span>
                  </p>
                </div>
              </section>
            ) : (
              <section>
                <a
                  href={userData.payment_link}
                  target="_blank"
                  className="bg-formisBlack15 border border-formisBlack15 h-[47px] px-4 text-white rounded-full mx-auto mt-10
                            hover:bg-transparent hover:text-formisBlack15 transition w-fit flex items-center justify-center"
                >
                  Complete Payment
                </a>
                <div className="text-center mt-10 text-xs">
                  <p>The ticket is invalid until the payment is completed.</p>
                </div>
              </section>
            )}
          </section>
        )}

        {userData && userData.payment_completed ? (
          <button
            onClick={downloadPDF}
            className="bg-white border border-white h-[47px] px-6 text-formisBlack15 rounded-full mx-auto mt-10
                        hover:bg-transparent hover:text-white transition w-fit flex items-center justify-center"
          >
            Download Ticket as PDF
          </button>
        ) : (
          ""
        )}
      </section>

      <Footer />
    </div>
  );
};

export default Ticket;
