import { useParams } from "react-router-dom";
import { blogPageData, featuredMain, featured } from "./blogData";
import Navbar from "../../components/Main/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import { FaTwitter } from "react-icons/fa";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const BlogPost = () => {
  const { blogId } = useParams();

  const blog =
    blogPageData.find((post) => post.id === blogId) ||
    featuredMain.find((post) => post.id === blogId) ||
    featured.find((post) => post.id === blogId);

  useGSAP(() => {
    ScrollTrigger.create({
      trigger: "#main-sidebar-container",
      start: "top top-=160px",
      end: "bottom 80%",
      pin: "#sidebar",
      scrub: true,
    });
  }, []);

  if (!blog) {
    return <div className="text-white">Blog not found</div>;
  }

  return (
    <div>
      <Navbar isLoaded={true} />

      <section
        id="main-blog-container"
        className="bg-white mt-[80px] py-[80px] px-[40px] lg:px-[60px] xl:px-[90px]"
      >
        <h6 className="text-[12px] font-medium tracking-widest mb-[36px]">
          COMPANY
        </h6>

        <h2
          className="text-[22px] md:text-[32px] md2:text-[48px] font-medium mb-[30px] lg:w-[65%]
                        leading-[26px] md:leading-[36px] md2:leading-[52px]"
        >
          {blog.title}
        </h2>

        <div className="lg:flex lg:gap-[30px]">
          <section className="lg:w-[55%]">
            <img src={blog.img} alt={blog.title} className="mb-10" />

            <section className="blog-content">
              {blog.content.map((block, index) => {
                switch (block.type) {
                  case "heading":
                    return (
                      <h2
                        key={index}
                        id={`section-${index}`}
                        className="text-[20px] md:text-[24px] font-semibold my-8"
                      >
                        {block.text}
                      </h2>
                    );
                  case "numbered heading":
                    return (
                      <h2
                        key={index}
                        className="text-[20px] md:text-[24px] font-semibold my-8"
                      >
                        {block.text}
                      </h2>
                    );
                  case "subheading":
                    return (
                      <h2
                        key={index}
                        className="text-[18px] md:text-[20px] font-semibold opacity-80 my-8"
                      >
                        {block.text}
                      </h2>
                    );
                  case "inline subheading":
                    return (
                      <h2
                        key={index}
                        className="inline text-[18px] md:text-[20px] font-semibold opacity-80 my-8"
                      >
                        {block.text}{" "}
                      </h2>
                    );
                  case "link":
                    return (
                      <a
                        key={index}
                        href={block.href}
                        target="_blank" rel="noreferrer"
                        className="underline text-[18px] md:text-[20px] font-semibold opacity-80 my-8"
                      >
                        {block.text}
                      </a>
                    );
                  case "paragraph":
                    return (
                      <p
                        key={index}
                        className="text-[16px] md:text-[18px] opacity-70 my-8"
                      >
                        {block.text}
                      </p>
                    );
                  case "styled paragraph":
                    return (
                      <p
                        key={index}
                        className="text-[20px] md:text-[24px] px-20 opacity-70 my-8"
                      >
                        {block.text}
                      </p>
                    );
                  case "italic paragraph":
                    return (
                      <p
                        key={index}
                        className="italic text-[16px] md:text-[18px] opacity-70 my-8"
                      >
                        {block.text}
                      </p>
                    );
                  case "inline paragraph":
                    return (
                      <p
                        key={index}
                        className="inline text-[16px] md:text-[18px] opacity-70 my-8"
                      >
                        {block.text}{" "}
                      </p>
                    );
                  case "inline bold":
                    return (
                      <p
                        key={index}
                        className="inline font-semibold text-[16px] md:text-[18px] opacity-70 my-8"
                      >
                        {block.text}
                      </p>
                    );

                  case "bold italic":
                    return (
                      <p
                        key={index}
                        className="italic font-semibold text-[16px] md:text-[18px] opacity-90"
                      >
                        {block.text}
                      </p>
                    );

                  case "bold":
                    return (
                      <p
                        key={index}
                        className="font-semibold text-[16px] md:text-[18px] opacity-90 my-8"
                      >
                        {block.text}
                      </p>
                    );

                  case "image":
                    return (
                      <img
                        onLoad={() => ScrollTrigger.refresh()}
                        key={index}
                        src={block.src}
                        alt={block.alt}
                        className="my-6"
                      />
                    );
                  case "quote":
                    return (
                      <blockquote
                        key={index}
                        className="text-[16px] md:text-[18px] italic pl-4 border-l-4 border-formisBlack15 opacity-70 my-8"
                      >
                        {block.text}
                      </blockquote>
                    );

                  case "ul item":
                    return (
                      <ul className="list-disc pl-8 text-[16px] md:text-[18px] opacity-70 my-2">
                        <li>
                          <h6 className="inline-block font-bold text-[18px] md:text-[20px]">
                            {block.listHeading}
                          </h6>{" "}
                          {block.text}
                        </li>
                      </ul>
                    );

                  case "break":
                    return (
                      <br />
                    );

                  default:
                    return null;
                }
              })}
            </section>
          </section>

          <section
            id="main-sidebar-container"
            className="lg:w-[45%] mt-[30px] lg:mt-0"
          >
            {/* Tags */}
            <div className="flex items-center justify-between bg-formisBlack15 bg-opacity-10 p-[30px] mb-[30px]">
              <h6 className="text-[12px] font-medium tracking-widest mb-[15px]">
                COMPANY
              </h6>
              <h6 className="border border-black border-opacity-30 px-4 py-1">
                FORMIS
              </h6>
            </div>

            {/* Share */}
            <div className="flex items-center justify-between bg-formisBlack15 bg-opacity-10 p-[30px]">
              <h6 className="text-[12px] font-medium tracking-widest mb-[15px]">
                SHARE
              </h6>
              <div className="flex gap-[50px]">
                <FaTwitter className="text-xl cursor-pointer" />
                <FaFacebookF className="text-xl cursor-pointer" />
                <FaLinkedinIn className="text-xl cursor-pointer" />
              </div>
            </div>

            {/* Sidebar */}
            <div id="sidebar" className="hidden lg:flex mt-[30px]">
              <ul className="border-l-2 border-formisBlack15 border-opacity-50 flex flex-col">
                {blog.content.map((block, index) => {
                  if (block.type === "heading") {
                    return (
                      <a
                        className="pl-[56px] border-l-4 border-transparent cursor-pointer py-2
                            hover:border-l-4 hover:border-formisBlack15 hover:border-opacity-50"
                        // href={`#section-${index}`}
                        onClick={(e) => {
                          e.preventDefault();
                          document
                            .getElementById(`section-${index}`)
                            ?.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        {block.text}
                      </a>
                    );
                  }
                })}
              </ul>
            </div>

          </section>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default BlogPost;
