import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitleUpdater = () => {
  const location = useLocation();

  const metaDescription = document.querySelector('meta[name="description"]');

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "KITSA | HEALTHCARE, SIMPLIFIED | INDIA";
        if (metaDescription)
          metaDescription.content =
            "KITSA™, your health isn’t just a priority—it’s personal. We’re not just delivering products; we’re delivering care, convenience, and a better way of living.";
        break;
      case "/hydrovibe2024":
        document.title = "MEET KITSA | HYDROVIBE";
        if (metaDescription)
          metaDescription.content =
            "Get ready to dive into HYDROVIBE 2024, the splashiest, most electrifying festival of the year! Picture this: jaw-dropping wave pool stunts, epic light and water shows, and a storm of DJ beats that'll keep you vibing all day long. There’s live music, hilarious stand-up acts, spontaneous flash mobs, and all the splash vibes you could ask for. Hungry? Thirsty? We’ve got you covered with food, drinks, and a flea market to keep the energy high. It’s going to be a wave of fun !";
        break;
      case "/privacy-policy":
        document.title = "MEET KITSA | Privacy";
        if (metaDescription)
          metaDescription.content =
            "KITSA™, your health isn’t just a priority—it’s personal. We’re not just delivering products; we’re delivering care, convenience, and a better way of living.";
        break;
      case "/shipping-and-refunds-policy":
        document.title = "MEET KITSA | Shipping and Refunds";
        if (metaDescription)
          metaDescription.content =
            "KITSA™, your health isn’t just a priority—it’s personal. We’re not just delivering products; we’re delivering care, convenience, and a better way of living.";
        break;
      case "/terms":
        document.title = "MEET KITSA | Terms";
        if (metaDescription)
          metaDescription.content =
            "KITSA™, your health isn’t just a priority—it’s personal. We’re not just delivering products; we’re delivering care, convenience, and a better way of living.";
        break;
      case "/hydrovibe2024/tickets":
        document.title = "MEET KITSA | Ticket";
        if (metaDescription)
          metaDescription.content =
            "Get ready to dive into HYDROVIBE 2024, the splashiest, most electrifying festival of the year! Picture this: jaw-dropping wave pool stunts, epic light and water shows, and a storm of DJ beats that'll keep you vibing all day long. There’s live music, hilarious stand-up acts, spontaneous flash mobs, and all the splash vibes you could ask for. Hungry? Thirsty? We’ve got you covered with food, drinks, and a flea market to keep the energy high. It’s going to be a wave of fun !";
        break;
      case "/connect":
        document.title = "MEET KITSA | Connect";
        if (metaDescription) metaDescription.content = "Talk To Us.";
        break;
      default:
        document.title = "MEET KITSA";
    }
  }, [location.pathname]);

  return null;
};

export default PageTitleUpdater;
