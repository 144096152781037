import { useState, useEffect } from "react";
import { slides, slidesTitle } from "./highlightsData";
import { IoMdStar } from "react-icons/io";
import { Link } from "react-router-dom";

const Highlights = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 9000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-[80vh] mobile-landscape:h-[90vh] relative">
      <section className="flex pt-5">
        {slidesTitle.map((title, index) => (
          <div key={index} className="flex items-center justify-center z-20 w-screen max-w-screen">
            <section className="flex justify-between bg-transparent text-formisWhitef5">
              <div
                className="cursor-pointer"
                onClick={() => setCurrentSlide(index)}
              >
                <div className="h-1 w-[21vw] bg-[#545454] opacity-70 rounded-full mb-1 lg:mb-4">
                  <div
                    className={`h-1 ${
                      currentSlide === index ? "w-full" : "w-0"
                    } bg-formisOrange`}
                  ></div>
                </div>
                {/* <h1 className="text-lg md:text-2xl lg:text-4xl font-bold">{title}</h1> */}
              </div>
            </section>
          </div>
        ))}
      </section>

      {slides.map((slide, index) => (
        <section
          key={index}
          style={{ backgroundImage: `url(${slide.img})` }}
          className={`h-[80vh] mobile-landscape:h-[90vh] w-[100vw] overflow-hidden bg-top bg-cover bg-no-repeat px-[40px] lg:px-[90px] 
            absolute top-0 left-0 z-10 flex items-end pb-20 mobile-landscape:pb-5 text-formisWhitef5
            ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            } transition-opacity duration-1000 ease-in-out absolute inset-0`}
        >
          <div className="absolute top-0 left-0 h-full w-full bg-formisBlack15 opacity-80 z-20"></div>
          <div className="z-30 w-screen flex flex-col md:flex-row gap-10 md:gap-4 lg:gap-6 xl:gap-0 items-end justify-between">
            {/* <div className="hidden md:block">
              <h1 className="text-5xl lg:text-9xl">{slide.leftHeader}</h1>
              <p className="">{slide.leftDesc}</p>
            </div> */}

            <div className="flex justify-start md:justify-start md:items-start w-full mt-3">
              <div className="flex flex-col justify-center items-center">
                <h4 className="self-start text-white text-5xl font-[500]">
                  {slide.leftHeader}
                </h4>
                <p className="self-start opacity-60 text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px]">
                  &nbsp;{slide.leftDesc}
                </p>
                <div className="text-2xl md:text-3xl text-white flex self-start">
                  <IoMdStar />
                  <IoMdStar />
                  <IoMdStar />
                  <IoMdStar />
                  <IoMdStar />
                </div>
              </div>
            </div>

            <div className="max-w-[490px] mobile-landscape:max-w-[550px] place-self-end">
              <h3 className="text-[22px] lg:text-[34px] font-medium leading-[1.75rem] lg:leading-[2.5rem] mb-4 md:mb-7 mobile-landscape:mb-3">
                {slide.rightHeader}
              </h3>
              <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] mb-7 mobile-landscape:mb-3 opacity-60">
                {slide.rightDesc}
              </p>
              <Link to="/hydrovibe2024">
                <button className="relative z-[100] text-[12px] md:text-[14px] bg-formisBlack15 border-formisBlack15 text-white h-[47px] px-6 rounded-full">
                  Unlock KITSA<span className="text-[7px] lg:text-[10px] align-super">™</span>.LIFE
                </button>
              </Link>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default Highlights;
