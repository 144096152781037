import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Events from "./pages/Events";
import Main from "./pages/Main";
import ScrollToTop from "./components/ScrollToTop";
import Ticket from "./pages/Ticket";
import Contact from "./pages/Contact";
import PageTitleUpdater from "./components/PageTitleUpdater";

import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import ShippingRefundsPolicy from "./pages/Policies/ShippingRefundsPolicy";
import TermsPolicy from "./pages/Policies/TermsPolicy";

import Blog from "./pages/Blog/Blog";
import BlogPost from "./pages/Blog/BlogPost";
import About from "./pages/About";

const App = () => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <BrowserRouter>
    <ScrollToTop />
    <PageTitleUpdater />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/hydrovibe2024" element={<Events />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/shipping-and-refunds-policy" element={<ShippingRefundsPolicy />} />
        <Route path="/terms" element={<TermsPolicy />} />
        <Route path="/hydrovibe2024/tickets" element={<Ticket />} />
        <Route path="/connect" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<BlogPost />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
