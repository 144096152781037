import AwardsMarquee from "../../AwardsMarquee";
import hero from "../../../assets/WebsiteHeader5.png";
import { Link } from "react-router-dom";

const Hero = ({ isLoaded }) => {
  return (
    <header
      className={`transition-opacity duration-1000 ${
        isLoaded ? "opacity-100" : "opacity-0"
      }`}
    >
      <section className="bg-cover bg-no-repeat h-[100svh] relative z-40 px-[40px] ">
        <section
          className="h-[80%] lg:h-[75%] pt-[80px] lg:pt-[80px] 3xl:pt-[150px]
                            mobile-landscape:h-[70%]"
        >
          <div className="flex flex-col justify-center">
            <div
              className="mt-8 lg:mt-[55px] header-text
                            max-w-[920px] mx-auto text-formisWhitef5 text-center
                            mobile-landscape:text-[20px] mobile-landscape:mt-0"
            >
              <h1
                className="mobile-landscape:leading-[30px]"
              >
                Health Essentials at Your Door. 20 Minutes—<br className="hidden mobile-landscape:block"/>Because Who Has Time
                to Wait?
              </h1>
            </div>

            <div
              className="font-light text-formisWhitef5 max-w-[850px] text-center mx-auto 
                            mt-7 mobile-landscape:mt-2"
            >
              <p className="text-[12px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[25px] text-white opacity-60 font-[200]">
                From daily health essentials to life-enhancing products, KITSA
                <span className="text-[7px] lg:text-[10px] align-super">
                  ™
                </span>{" "}
                delivers everything you need in just 20 minutes. No delivery
                fees, no hidden charges, no minimum cart value—just fast,
                simple, reliable service. Because there's a cure for everything.{" "}
                <span className="border-b-2 border-[#f1edeb] inline-block">
                  And you've got KITSA
                  <span className="text-[7px] lg:text-[10px] align-super">
                    ™
                  </span>
                  .
                </span>
              </p>
            </div>

            <div className="flex flex-col md:flex-row gap-2 md:gap-5 items-center justify-center font-[500] mt-10 mobile-landscape:mt-5">
              <a
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=919773910084&text=Hi%21+Kitsa&type=phone_number&app_absent=0"
                className="text-formisBlack15 text-[12px] md:text-[14px] border border-[#f1edeb] bg-[#f1edeb] h-[47px] rounded-full px-4 
                          hover:bg-transparent hover:text-white transition-colors cursor-pointer flex items-center justify-center
                          mobile-landscape:h-[38px]"
              >
                Order Now On WhatsApp
              </a>
              <Link to="/hydrovibe2024">
                <button
                  className="hidden lg:block text-[#f1edeb] text-[14px] border border-[#f1edeb] h-[47px] rounded-full px-4 
                                hover:bg-[#f1edeb] hover:text-formisBlack15 transition"
                >
                  Unlock KITSA
                  <span className="text-[7px] lg:text-[10px] align-super">
                    ™
                  </span>{" "}
                  .LIFE
                </button>
              </Link>
            </div>
          </div>
        </section>

        <div
          className="mt-14 h-[20%] lg:h-[25%] px-[20px] md:mx-[40px] lg:mx-[60px] 2xl:mx-[90px]
                        mobile-landscape:h-[30%]"
        >
          <AwardsMarquee />
        </div>
      </section>

      <section
        style={{ backgroundImage: `url(${hero})` }}
        className="h-[70vh] lg:h-screen bg-cover lg:bg-cover bg-center bg-no-repeat 
                  -mt-[20%] md:-mt-[30%] lg:-mt-[12%] xl:-mt-[8%] 3xl:-mt-[10%]
                  mobile-landscape:mt-5"
      ></section>
    </header>
  );
};

export default Hero;