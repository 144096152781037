import Accordian from "./Accordian";
import tm from "../../../assets/tm-symbol-white.png";
import tmblack from "../../../assets/tm-symbol.png";
import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <section className="my-20 bg-formisBlack15 px-[40px] xl:px-[90px]">
      <div className="text-4xl md:text-6xl lg:text-[5.5rem] text-white font-medium font-[Hind] mb-10 lg:mb-10">
        <h1 className="leading-[2.5rem] md:leading-[4.5rem] lg:leading-[7rem]">
          Frequently Asked Questions
          <span className="text-formisOrange">.</span>
        </h1>
      </div>

      <div className="flex flex-col gap-3">
        <Accordian title="Q: What kind of products does KITSA™ deliver?">
          <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            A:{" "}
            <span className="">
              KITSA<span className="text-[50%] align-super">™</span>{" "}
            </span>{" "}
            delivers everything from daily health essentials to
            lifestyle-enhancing products . You’ll get every cure that
            contributes to making you feel as healthy as you were in your
            youthful fit —and then some.
          </p>
        </Accordian>
        <Accordian title="Q: Do you charge delivery fees for online medicine?">
          <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            A: Nope. At{" "}
            <span className="">
              KITSA
              <span className="text-[50%] align-super">™</span>{" "}
            </span>{" "}
            , healthcare is simplified —there are no hidden fees, no delivery
            charges, and no minimum order value. Just fast, reliable service.
          </p>
        </Accordian>
        <Accordian title="Q: Do I need an app to use KITSA™?">
          <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            A: Absolutely not. All you need is WhatsApp.{" "}
            <span className="">
              KITSA
              <span className="text-[50%] align-super">™</span>{" "}
            </span>{" "}
            keeps it simple, so you never need to worry about downloading
            another app. The cure is around the corner, without the clutter.
          </p>
        </Accordian>
        <Accordian title="Q: How do I join KITSA™.LIFE?">
          <p className="mb-4 text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            A: When you order through{" "}
            <span className="">
              KITSA
              <span className="text-[50%] align-super">™</span>{" "}
            </span>{" "}
            , you automatically unlock{" "}
            <span className="">
              KITSA
              <span className="text-[50%] align-super">™</span>{" "}
            </span>{" "}
            .LIFE. That means free access to exclusive life-enhancing events and
            experiences designed to keep you energized and youthful fit.
          </p>
          <Link to="/hydrovibe2024">
            <button
              className="text-formisBlack15 border border-formisBlack15 text-[12px] md:text-[14px] font-[500] bg-[#f1edeb] h-[47px] rounded-full px-4 hover:bg-transparent 
                            hover:border hover:border-white hover:text-white transition-colors"
            >
              UNLOCK{" "}
              <span className="">
                KITSA
                <span className="text-[50%] align-super">™</span>{" "}
              </span>{" "}
              .LIFE
            </button>
          </Link>
        </Accordian>
      </div>
    </section>
  );
};

export default FAQ;
