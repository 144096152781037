import gif from "../../assets/events/event3.gif";
import { FaArrowUp } from "react-icons/fa6";

const Schedule = () => {
  return (
    <div className="mx-[40px] lg:mx-[60px] xl:mx-[90px] py-[80px] lg:py-[160px] text-white">
      <section className="mb-10 md:mb-0 flex flex-col lg:grid grid-cols-3 text-white">
        <a
          href="https://www.kitsa.in/"
          target="_blank"
          rel="noreferrer"
          className="subheader-text"
        >
          kitsa.in
        </a>
        <a href="mailto:hydrovibe2024@kitsa.in" className="subheader-text">
          hydrovibe2024@kitsa.in
        </a>
        <div>
          <a
            href="tel:+91-9311960160"
            className="header-text font-[500] hover:text-[#cebeb8] transition-colors"
          >
            +91 93119 60160
          </a>
          <a
            className="subheader-text block"
            href="https://maps.app.goo.gl/LrEJYoRapJtKMpBK9"
            target="_blank"
            rel="noreferrer"
          >
            {/* GymKhana Club, Sector 29, Gurugram, Haryana 122001 */}
            Backyard Sports Club, Sector 59, Gurugram, Haryana 122102
          </a>
        </div>
      </section>

      <section className="flex justify-between lg:grid grid-cols-3 lg:items-center">
        <h1 className="text-2xl lg:text-8xl">
          Event
          <br />
          Flow
        </h1>
        <div></div>
        <div className="subheader-text flex flex-col gap-1">
          <p>@HydrovibeFestival</p>
          <p>#Hydrovibe2024</p>
          <p>#MakeWaves</p>
          <p>#RainAndBeats</p>
        </div>
      </section>

      <section>
        <h3 className="header-text my-10">HYDROVIBE SPOTLIGHT</h3>
        <div className="flex flex-col lg:grid grid-cols-4">
          <section className="flex flex-col justify-between lg:pr-10 lg:border-r border-white">
            <div className="">
              <h4 className="text-[28px] lg:text-[22px] xl:text-[28px] mb-4">
                5:00 PM
              </h4>
              <p className="text-[12px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[25px] opacity-60 font-[800] mb-4">
                Artistic Water Show & Wave Pool Spectacle
              </p>
              <p className="subheader-text">
                Experience a stunning artistic water show , blending mesmerizing
                water choreography with jet ski stunts and flyboard acrobatics,
                turning the wave pool into a canvas of flowing beauty.
              </p>
            </div>

            <hr className="text-white my-10" />

            <div className="">
              <h4 className="text-[28px] lg:text-[22px] xl:text-[28px] mb-4">
                7:00 PM
              </h4>
              <p className="text-[12px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[25px] opacity-60 font-[800] mb-4">
                Flash Mobs & Light Show
              </p>
              <p className="subheader-text">
                Surprise flash mobs paired with an enchanting light and water
                spectacle.
              </p>
            </div>

            <hr className="block lg:hidden text-white my-10" />
          </section>

          <section className="col-span-2 lg:px-10 lg:border-r">
            <div className="pb-10">
              <h4 className="text-[28px] lg:text-[22px] xl:text-[28px] mb-4">
                8:00 PM
              </h4>
              <p className="text-[12px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[25px] opacity-60 font-[800] mb-4">
                Comedy Acts
              </p>
              <p className="subheader-text">
                Top comedians serve up laughs before the night amps up.
              </p>
            </div>

            <img src={gif} alt="Schedule" className="max-w-[100%] mb-10" />

            <div className="flex">
              <div className=" pr-10">
                <h4 className="text-[28px] lg:text-[22px] xl:text-[28px] mb-4">
                  9:00 PM
                </h4>
                <p className="text-[12px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[25px] opacity-60 font-[800] mb-4">
                  Bollywood Celebrity Performance
                </p>
                <p className="subheader-text">
                  A captivating 30-minute set by a Bollywood star to elevate the
                  night.
                </p>
              </div>
            </div>

            <hr className="block lg:hidden my-10" />
          </section>

          <section className="flex flex-col justify-between lg:pl-10">
            <div>
              <h4 className="text-[28px] lg:text-[22px] xl:text-[28px] mb-4">
                All Day
              </h4>
              <p className="text-[12px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[25px] opacity-60 font-[800] mb-4">
                Rain & Splash DJ Party
              </p>
              <p className="subheader-text">
                Dance through the night under controlled rain and splashes,
                keeping the vibe alive!
              </p>
            </div>

            <hr className="text-white my-10" />

            <div className="">
              <h4 className="text-[28px] lg:text-[22px] xl:text-[28px] mb-4">
                9:30 PM – 12:30 AM
              </h4>
              <p className="text-[12px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[25px] opacity-60 font-[800] mb-4">
                DJ NYK & Fire-Fog Show
              </p>
              <p className="subheader-text">
                DJ NYK takes control for a 3-hour power-packed set with
                synchronized fire, fog, and dazzling lights.
              </p>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default Schedule;
