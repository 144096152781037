import React from "react";

const VideoModal = ({ open, onClose, children }) => {
  return (
    <main
      onClick={onClose}
      className={`fixed inset-0 flex justify-center items-center transition-colors duration-500 ${
        open ? "visible bg-formisBlack15/80" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        } relative`}
      >
        {children}
      </div>
    </main>
  );
};

export default VideoModal;
