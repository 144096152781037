import { useState } from "react";
import Navbar from "../components/Main/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Header from "../components/Events/Header";
import Hero from "../components/Events/Hero";
import test from "../assets/Test.mp4";
import herobg from "../assets/events/event-hero.jpg";
import Schedule from "../components/Events/Schedule";
import Lineup from "../components/Events/Lineup";

const Events = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <main className="overflow-hidden">
      <Navbar isLoaded={true} isRegistrationFormOpen={isOpen} />

      <section id="hydrovibe-hero" className="relative h-[100vh]">
        {/* Video Background */}
        <div className="w-screen h-screen">
          <div className="w-full h-full bg-formisBlack15 absolute top-0 left-0 opacity-50 z-[9]"></div>
          <video
            loop
            autoPlay
            playsInline
            preload="auto"
            muted={true}
            className="relative z-0 w-screen h-screen object-cover"
          >
            <source src={test} />
          </video>
        </div>

        {/* Hero */}
        <section
          
          className={`h-screen w-screen  `}
        >
          {/* <div className="w-full h-full bg-formisBlack15 absolute top-0 left-0 opacity-70"></div> */}
          <div className={`absolute top-0  w-full ${
            isOpen ? "z-30" : "z-10"
          }`}>
            <Hero isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        </section>

        {/* <section
          className={`absolute top-0 inset-x-0 flex items-center justify-center px-[20px] h-[100%] text-center ${
            isOpen ? "z-30" : "z-10"
          }`}
        >
          <Hero isOpen={isOpen} setIsOpen={setIsOpen} />
        </section> */}
      </section>

      <Header />
      <Schedule />
      <Lineup />
      <Footer />
    </main>
  );
};

export default Events;
