// import { useEffect, useState } from "react";
// import Modal from "./Modal";
// import RegistrationForm from "./RegistrationForm/RegistrationForm";
// import gsap from "gsap";
// import whitelogo from "../../assets/KITSAWhite3.png";
// import img1 from "../../assets/events/form.png";

// import { Toaster, toast } from "sonner";
// import { useNavigate } from "react-router-dom";

// const Hero = ({ isOpen, setIsOpen }) => {
//   gsap.config({nullTargetWarn:false});

//   const navigate = useNavigate();
//   const [step, setStep] = useState(1);
//   const [remainingTicketTypes, setRemainingTicketTypes] = useState([]);
//   const [eventType, setEventType] = useState([]);
//   const [phoneNumber, setPhoneNumber] = useState("");

//   const [loading, setLoading] = useState(false);

//   const verifyToken = async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(
//         "https://kitsa.asia/event-registration/verify-token",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             token: localStorage.getItem("accessToken"),
//           }),
//         }
//       );
//       const data = await response.json();
//       if (response.ok) {
//         setPhoneNumber(data.mobile);
//         fetchTicketTypes();
//       } else {
//         setStep(1);
//         setIsOpen(true);
//       }
//     } catch (error) {
//       return ;
//     } finally {
//       setLoading(false);
//     }
//   };

//   const verifyBookingData = async () => {
//     setLoading(true);
//     try {
//       const access = localStorage.getItem("accessToken");
//       const response = await fetch(
//         "https://kitsa.asia/event-registration/user-event-booking",
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${access}`,
//           },
//         }
//       );

//       const data = await response.json();

//       if (response.ok) {
//         navigate("/hydrovibe2024/tickets");
//       } else {
//         setStep(3);
//         setIsOpen(true);
//       }
//     } catch (error) {
//       // toast.error(`${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchTicketTypes = async () => {
//     setLoading(true);
//     try {
//       const access = localStorage.getItem("accessToken");
//       const response = await fetch(
//         "https://kitsa.asia/event-registration/latest-event-details",
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${access}`,
//           },
//         }
//       );
//       const data = await response.json();

//       if (response.ok) {
//         setRemainingTicketTypes(data.tickets);
//         setEventType(data.event);
//         verifyBookingData();
//         return true;
//       } else {
//         toast.error("Something went wrong, Please try again later.");
//       }
//     } catch (error) {
//       // toast.error("Error occurred while fetching ticket types:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleBookButtonClick = async () => {
//     if (localStorage.getItem("accessToken") !== null) {
//       verifyToken();
//     } else {
//       setIsOpen(true);
//     }
//   };

//   useEffect(() => {
//     let mm = gsap.matchMedia();
//     if (isOpen) {
//       gsap.fromTo(
//         "#register-text",
//         { y: 100 },
//         { y: 0, duration: 1, ease: "power2.inOut" }
//       );
//       gsap.fromTo(
//         "#register-image",
//         { x: 650 },
//         { x: 0, duration: 1.5, ease: "power2.inOut" }
//       );
//     }
//   }, [isOpen]);

//   const handleClose = () => {
//     setIsOpen(false);
//   };

//   return (
//     <div>
//       <div className="flex items-center justify-center -mt-[45%] md:mt-0 mx-auto h-[100svh] w-screen font-[Hind] px-[40px]">
//         <h1
//           id="hydrovibe-text"
//           className="tracking-tighter relative z-0 font-bold
//           text-[50px] md:text-[100px] lg:text-[170px] xl:text-[210px] 2xl:text-[260px] 3xl:text-[300px] 4xl:text-[350px]"
//         >
//           <span className="hydrovibe">HYDROVIBE</span>{" "}
//           <span
//             className="hydrovibe2 absolute -z-10 left-0 lg:left-1
//             text-[51px] md:text-[101px] lg:text-[171px] xl:text-[211px] 2xl:text-[261px] 3xl:text-[301px] 4xl:text-[351px]"
//           >
//             HYDROVIBE
//           </span>
//         </h1>

//         <h2
//           id="tagline"
//           className="opacity-0 absolute text-8xl text-white
//           header-text
//           max-w-[920px] mx-auto text-center px-2 md:px-0
//           mobile-landscape:text-[20px] mobile-landscape:mt-0"
//         >
//           Where Water Flows,
//           <br />
//           <span className="bg-[#e8dcd8] text-formisBlack15 px-4 rounded-full inline-block py-2">
//             Vibes Grow
//           </span>
//         </h2>

//         <h2
//           id="tagline2"
//           className="opacity-0 absolute text-8xl text-white
//           header-text
//           max-w-[920px] mx-auto text-center
//           mobile-landscape:text-[20px] mobile-landscape:mt-0"
//         >
//           Twice a Year,{" "}
//           <span className="bg-[#e8dcd8] text-formisBlack15 px-4 rounded-full inline-block py-2">
//             One Unforgettable Evening
//           </span>
//         </h2>

//         <div id="tagline3" className="header-text absolute text-white">
//           <h2 className="max-w-[920px] mx-auto text-center mobile-landscape:text-[20px] mobile-landscape:mt-0">
//             It's KITSA
//             <span className="text-[50%] align-super">™</span>
//             .LIFE
//           </h2>
//           <h2 className="max-w-[920px] mx-auto text-center mobile-landscape:text-[20px] mobile-landscape:mt-0">
//             No Rules, No Walls -{" "}
//             <span className="bg-[#e8dcd8] text-formisBlack15 px-4 rounded-full inline-block">
//               Be Unstoppable
//             </span>
//           </h2>
//         </div>
//       </div>

//       <div className="flex items-center justify-center absolute inset-x-0 bottom-[40%] md:bottom-[27%]">
//         <button
//           onClick={handleBookButtonClick}
//           disabled={loading}
//           className={`text-formisWhitef5 relative border border-white font-bold text-[14px] h-[47px] rounded-full w-[150px]
//                       ${!loading ? "hover:bg-white hover:text-formisBlack15 transition" : "transition"}`}
//         >
//           {loading ? (
//             <div className="loader mx-auto"></div>
//           ) : (
//             "Book Your Tickets"
//           )}
//         </button>
//       </div>

//       {isOpen && (
//         <Modal open={isOpen}>
//           <div
//             style={{ backgroundImage: `url(${img1})` }}
//             className="relative z-[100] bg-cover bg-center lg:bg-top bg-formisWhitef0 text-formisBlack14
//                       h-screen w-screen flex justify-between"
//           >
//             <div className="h-full w-full bg-formisBlack15 absolute top-0 left-0 opacity-60 z-10"></div>

//             <section
//               className="relative z-[100] ml-[20px] lg:ml-[90px]
//                               hidden md:flex flex-col justify-between"
//             >
//               <div className="h-[100px] flex items-center">
//                 <img src={whitelogo} alt="logo" className="max-w-[132px]" />
//               </div>

//               <div className="mb-20 max-w-[100%] lg:max-w-[85%]">
//                 <p className="text-lg text-left xl:text-[28px] mb-6 xl:leading-[2.5rem] text-formisWhitef5 font-[500]">
//                   This is{" "}
//                   <span className="text-formisOrange font-[600]">
//                     HYDROVIBE 2024
//                   </span>{" "}
//                   -where the water roars, the lights ignite the night, and music
//                   electrifies your soul. A world of epic stunts, fire and rain,
//                   with DJ NYK leading you into a 3-hour journey of pure
//                   euphoria.
//                 </p>
//                 <div className="flex justify-start text-[18px] lg:text-[20px] xl:text-[22px] text-formisWhitef5 font-[500]">
//                   <p>Enter The Vibe</p>
//                 </div>
//               </div>
//             </section>

//             <section
//               className="relative z-[100] bg-formisWhitef0 h-screen md:h-[98vh] p-[32px] md:p-[40px] lg:p-[56px] w-full md:w-[380px] lg:w-[520px] flex-shrink-0
//                         md:mx-3 my-auto flex flex-col md:rounded-[10px]"
//             >
//               <p
//                 onClick={handleClose}
//                 className="place-self-end cursor-pointer font-bold text-xl z-[1]"
//               >
//                 Close
//               </p>
//               <div className="-mt-[25%] md:mt-0 h-[95%] flex flex-col items-center justify-center">
//                 <div className="mb-6 overflow-hidden h-9">
//                   <p
//                     id="register-text"
//                     className="text-xl lg:text-[32px] font-bold text-center w-fit"
//                   >
//                     Get Your Tickets Now!
//                   </p>
//                 </div>

//                 <RegistrationForm
//                   step={step}
//                   setStep={setStep}
//                   remainingTicketTypes={remainingTicketTypes}
//                   setRemainingTicketTypes={setRemainingTicketTypes}
//                   eventType={eventType}
//                   setEventType={setEventType}
//                   phoneNumber={phoneNumber}
//                   setPhoneNumber={setPhoneNumber}
//                   verifyBookingData={verifyBookingData}
//                 />
//               </div>
//             </section>

//             <div className="absolute">
//               <Toaster
//                 position="top-right"
//                 expand={false}
//                 closeButton
//                 richColors
//                 visibleToasts={2}
//               />
//             </div>
//           </div>
//         </Modal>
//       )}
//       <Toaster
//         position="top-right"
//         expand={false}
//         closeButton
//         richColors
//         visibleToasts={2}
//       />
//     </div>
//   );
// };

// export default Hero;

import { useEffect, useState } from "react";
import Modal from "./Modal";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import gsap from "gsap";
import whitelogo from "../../assets/KITSAWhite3.png";
import img1 from "../../assets/events/form.png";
import { FaArrowUp } from "react-icons/fa";
import { MdOutlinePlayCircleFilled } from "react-icons/md";
import { Toaster, toast } from "sonner";
import { useNavigate } from "react-router-dom";
import VideoModal from "./VideoModal";

const Hero = ({ isOpen, setIsOpen }) => {
  gsap.config({ nullTargetWarn: false });

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [remainingTicketTypes, setRemainingTicketTypes] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isVideoOpen || isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVideoOpen || isOpen]);

  useEffect(() => {
    const targetDate = new Date(new Date().getFullYear(), 10, 9);
    const currentDate = new Date();

    const timeDifference = targetDate - currentDate;

    const days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    setDaysLeft(days);
  }, []);

  const verifyToken = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://kitsa.asia/event-registration/verify-token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: localStorage.getItem("accessToken"),
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPhoneNumber(data.mobile);
        fetchTicketTypes();
      } else {
        setStep(1);
        setIsOpen(true);
      }
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  };

  const verifyBookingData = async () => {
    setLoading(true);
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kitsa.asia/event-registration/user-event-booking",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        navigate("/hydrovibe2024/tickets");
      } else {
        setStep(3);
        setIsOpen(true);
      }
    } catch (error) {
      // toast.error(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchTicketTypes = async () => {
    setLoading(true);
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kitsa.asia/event-registration/latest-event-details",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setRemainingTicketTypes(data.tickets);
        setEventType(data.event);
        verifyBookingData();
        return true;
      } else {
        toast.error("Something went wrong, Please try again later.");
      }
    } catch (error) {
      // toast.error("Error occurred while fetching ticket types:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBookButtonClick = async () => {
    if (localStorage.getItem("accessToken") !== null) {
      verifyToken();
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    let mm = gsap.matchMedia();
    if (isOpen) {
      gsap.fromTo(
        "#register-text",
        { y: 100 },
        { y: 0, duration: 1, ease: "power2.inOut" }
      );
      gsap.fromTo(
        "#register-image",
        { x: 650 },
        { x: 0, duration: 1.5, ease: "power2.inOut" }
      );
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="">
      <section className="text-white flex flex-col justify-between sm:h-[100svh] pt-[80px] mobile-landscape:pt-[40px] md:pt-[120px] lg:pt-[90px] 2xl:pt-[120px]">
        <div>
          <section className="text-[14px] 2xl:text-[16px] pl-[40px] lg:pl-[60px] xl:pl-[90px] 2xl:mb-10 mobile-landscape:hidden">
            <p className="opacity-60">Artists Line Up | Revealing Soon.</p>
            <a
              href="https://www.kitsa.in/hydrovibe2024"
              target="_blank"
              rel="noreferrer"
            >
              kitsa.in/hydrovibe2024
            </a>
            <div>
              <a href="mailto:hydrovibe2024@kitsa.in">
                hydrovibe2024@kitsa.in
              </a>
            </div>
            <a href="tel:+91-9311960160">+91 93119 60160</a>
          </section>

          <section className="flex flex-col md:flex-row md:items-center justify-end md:gap-14">
            <div
              className="flex items-center justify-between gap-10 
                        mx-[40px] lg:mx-0"
            >
              <p>@kitsaindia</p>

              <div className="mobile-landscape:scale-[0.6] scale-75 md:scale-100 border w-fit p-1 rounded-full">
                <div
                  onClick={() => setIsVideoOpen(true)}
                  className="cursor-pointer"
                >
                  <PlayButton />
                </div>
              </div>
            </div>

            <div
              className="bg-[#cebeb8] mobile-landscape:h-[45px] h-[45px] md:h-[60px] lg:h-[50px] 2xl:h-[60px] text-formisBlack15 font-bold flex items-center justify-center
                          text-[13px] xl:text-[18px] 2xl:text-[20px] mt-2 md:mt-0 
                          pr-[40px] lg:pr-[60px] xl:pr-[90px] pl-[40px]"
            >
              <span>{daysLeft} DAYS LEFT. GRAB IT BEFORE IT RUNS OUT.</span>
            </div>
          </section>
        </div>

        <div>
          <section className="flex flex-col justify-end w-full mb-5">
            <div className="pl-[40px] lg:pl-[60px] xl:pl-[90px] mt-2">
              <h3 className="text-[18px] md:text-[24px] 2xl:text-[30px] mobile-landscape:text-[18px] leading-3">
                A KITSA.LIFE CONCERT
              </h3>
              <h2 className="text-[40px] md:text-[60px] 2xl:text-[80px] tracking-wider rumble-brave mobile-landscape:text-[40px]">
                HYDROVIBE 2024
              </h2>
              <h3 className="text-[18px] md:text-[24px] 2xl:text-[30px] mobile-landscape:text-[18px]">
                ONE STAGE | ONE NIGHT - ONE{" "}
                <span className="font-bold">UNFORGETTABLE</span> VIBE.
              </h3>
            </div>

            {/* Desktop Banner */}
            <div
              className="bg-[#cebeb8] mobile-landscape:h-[45px] h-[50px] 2xl:h-[60px] text-formisBlack15 font-bold hidden md:flex items-center justify-between
                          text-[16px] xl:text-[18px] 2xl:text-[20px] mt-5
                          pl-[40px] lg:pl-[60px] xl:pl-[90px] pr-[40px]
                          mr-[40px] lg:mr-[60px] xl:mr-[90px] mobile-landscape:hidden"
            >
              <a
                href="https://maps.app.goo.gl/LrEJYoRapJtKMpBK9"
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-2"
              >
                BACKYARD SPORTS CLUB | GURUGRAM
                <FaArrowUp className="rotate-45"/>
              </a>
              <span>|</span>
              <span>SATURDAY NOVEMBER 9, 2024</span>
            </div>

            {/* Mobile Banner */}
            <div
              className="bg-[#cebeb8] h-[45px] md:h-[60px] text-formisBlack15 font-bold md:hidden flex items-center
                          text-[13px] xl:text-[18px] 2xl:text-[20px] mt-2 md:mt-0 
                          pl-[40px] mr-[40px]"
            >
              <a
                href="https://maps.app.goo.gl/LrEJYoRapJtKMpBK9"
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-2"
              >
                BACKYARD SPORTS CLUB | GURUGRAM
                <FaArrowUp className="rotate-45"/>
              </a>
            </div>
            <div
              className="bg-[#cebeb8] h-[45px] md:h-[60px] text-formisBlack15 font-bold md:hidden flex items-center
                          text-[13px] xl:text-[18px] 2xl:text-[20px] mt-2 md:mt-0 
                          pl-[40px] mr-[40px]"
            >
              <span>SATURDAY NOVEMBER 9, 2024</span>
            </div>

            <div className="flex items-center justify-between px-[40px] lg:px-[60px] xl:px-[90px] pr-[40px] mobile-landscape:mt-3 mt-7">
              <img
                src={whitelogo}
                alt="KITSA"
                className="w-[25vw] lg:w-[20vw] 2xl:w-[25vw] mobile-landscape:w-[15vw]"
              />
              <button
                className={`text-formisWhitef5 relative border border-white font-bold text-[14px] h-[47px] mobile-landscape:scale-75 scale-75 md:scale-100  rounded-full w-[170px]
                      ${
                        !loading
                          ? "hover:bg-white hover:text-formisBlack15 transition"
                          : "transition"
                      }`}
                onClick={handleBookButtonClick}
              >
                {loading ? (
                  <div className="loader mx-auto"></div>
                ) : (
                  "BOOK YOUR TICKETS"
                )}
              </button>
            </div>
          </section>
        </div>
      </section>

      {isVideoOpen && (
        <VideoModal open={isVideoOpen} onClose={() => setIsVideoOpen(false)}>
          <iframe
            src="https://www.youtube.com/embed/15PLFSIMB3o?si=4jtdrsyoSqbzlSOI"
            title="Hydrovibe Teaser"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="rounded-[10px] h-[20vh] md:h-[80vh] w-[90vw] relative top-10"
          ></iframe>
        </VideoModal>
      )}

      {/* <div className="flex items-center justify-center absolute inset-x-0 bottom-[40%] md:bottom-[27%]">
        <button
          onClick={handleBookButtonClick}
          disabled={loading}
          className={`text-formisWhitef5 relative border border-white font-bold text-[14px] h-[47px] rounded-full w-[150px]
                      ${!loading ? "hover:bg-white hover:text-formisBlack15 transition" : "transition"}`}
        >
          {loading ? (
            <div className="loader mx-auto"></div>
          ) : (
            "Book Your Tickets"
          )}
        </button>
      </div> */}

      {/* Registration Form */}
      {isOpen && (
        <Modal open={isOpen}>
          <div
            style={{ backgroundImage: `url(${img1})` }}
            className="relative z-[100] bg-cover bg-center lg:bg-top bg-formisWhitef0 text-formisBlack14
                      h-screen w-screen flex justify-between"
          >
            <div className="h-full w-full bg-formisBlack15 absolute top-0 left-0 opacity-60 z-10"></div>

            <section
              className="relative z-[100] ml-[20px] lg:ml-[90px]
                              hidden md:flex flex-col justify-between"
            >
              <div className="h-[100px] flex items-center">
                <img src={whitelogo} alt="logo" className="max-w-[132px]" />
              </div>

              <div className="mb-20 max-w-[100%] lg:max-w-[85%]">
                <p className="text-lg text-left xl:text-[28px] mb-6 xl:leading-[2.5rem] text-formisWhitef5 font-[500]">
                  This is{" "}
                  <span className="text-formisOrange font-[600]">
                    HYDROVIBE 2024
                  </span>{" "}
                  -where the water roars, the lights ignite the night, and music
                  electrifies your soul. A world of epic stunts, fire and rain,
                  with DJ NYK leading you into a 3-hour journey of pure
                  euphoria.
                </p>
                <div className="flex justify-start text-[18px] lg:text-[20px] xl:text-[22px] text-formisWhitef5 font-[500]">
                  <p>Enter The Vibe</p>
                </div>
              </div>
            </section>

            <section
              className="relative z-[100] bg-formisWhitef0 h-screen md:h-[98vh] p-[32px] md:p-[40px] lg:p-[56px] w-full md:w-[380px] lg:w-[520px] flex-shrink-0
                        md:mx-3 my-auto flex flex-col md:rounded-[10px]"
            >
              <p
                onClick={handleClose}
                className="place-self-end cursor-pointer font-bold text-xl z-[1]"
              >
                Close
              </p>
              <div className="-mt-[25%] md:mt-0 h-[95%] flex flex-col items-center justify-center">
                <div className="mb-6 overflow-hidden h-9">
                  <p
                    id="register-text"
                    className="text-xl lg:text-[32px] font-bold text-center w-fit"
                  >
                    Get Your Tickets Now!
                  </p>
                </div>

                <RegistrationForm
                  step={step}
                  setStep={setStep}
                  remainingTicketTypes={remainingTicketTypes}
                  setRemainingTicketTypes={setRemainingTicketTypes}
                  eventType={eventType}
                  setEventType={setEventType}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  verifyBookingData={verifyBookingData}
                />
              </div>
            </section>

            <div className="absolute">
              <Toaster
                position="top-right"
                expand={false}
                closeButton
                richColors
                visibleToasts={2}
              />
            </div>
          </div>
        </Modal>
      )}
      <Toaster
        position="top-right"
        expand={false}
        closeButton
        richColors
        visibleToasts={2}
      />
    </div>
  );
};

export default Hero;

const PlayButton = () => {
  return (
    <div
      className="relative flex items-center justify-center size-[120px] lg:size-[80px] 2xl:size-[120px]"
    >
      {/* SVG for circular text */}
      <svg
        className="absolute rotating-text"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
      >
        <path
          id="circlePath"
          d="M 10, 50
            a 40,40 0 1,1 80,0
            40,40 0 1,1 -80,0"
          fill="none"
          stroke="none"
        />
        <text fontSize="9" fontWeight="bold" fill="#FFFFFF">
          <textPath href="#circlePath" startOffset="7%">
            GET YOUR TICKETS NOW
          </textPath>
          <textPath href="#circlePath" startOffset="60%">
            WATCH THE TEASER
          </textPath>
        </text>
      </svg>

      {/* Play button */}
      {/* <div className="bg-white rounded-full p-3 flex items-center justify-center cursor-pointer"> */}
      <MdOutlinePlayCircleFilled className="text-6xl lg:text-5xl 2xl:text-6xl text-white" />
      {/* </div> */}
    </div>
  );
};
