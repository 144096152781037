import { useEffect, useState } from "react";
import { toast } from "sonner";
import "./RegistrationForm.css";

const RegistrationForm = ({
  step,
  setStep,
  remainingTicketTypes,
  setRemainingTicketTypes,
  eventType,
  setEventType,
  phoneNumber,
  setPhoneNumber,
  verifyBookingData,
}) => {
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [tentativeTime, setTentativeTime] = useState("");
  const [quantity, setQuantity] = useState(1);

  const [finalResponse, setFinalResponse] = useState();

  const [ticketType, setTicketType] = useState("");
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log(finalResponse);
  // }, [finalResponse]);


  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        "https://kitsa.asia/event-registration/send-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobile: phoneNumber,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setStep(2);
        toast.success("OTP sent successfully!");
      } else {
        toast.error(`Failed to send OTP: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Error occurred while sending OTP: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        "https://kitsa.asia/event-registration/verify-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobile: phoneNumber,
            otp: otp,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("accessToken", data.access);
        toast.success("OTP verified successfully!");
        verifyBookingData();
        setStep(3);
        fetchTicketTypes();
      } else {
        toast.error(`Incorrect OTP`);
      }
    } catch (error) {
      toast.error("Error occurred while sending OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTicketTypes = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kitsa.asia/event-registration/latest-event-details",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setRemainingTicketTypes(data.tickets);
        setEventType(data.event);
        // toast.success("Ticket types fetched successfully!");
      } else {
        toast.error("Failed to fetch ticket types.");
      }
    } catch (error) {
      toast.error("Error occurred while fetching ticket types:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(
        "https://kitsa.asia/event-registration/book-tickets",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
          body: JSON.stringify({
            mobile: phoneNumber,
            name,
            email,
            age,
            gender,
            event: eventType.id,
            attending_time: tentativeTime,
            ticket_quantity: quantity,
            ticket: ticketType,
          }),
        }
      );
      const data = await response.json();

      if (response.ok) {
        if (data.error_detail) {
          toast.error("Something went wrong");
        } else {
          setFinalResponse(data);
          // window.open(data.payment_link, "_blank");
          // window.location.href = "/hydrovibe2024/tickets";
          window.open(data.payment_link, "_blank");
          setTimeout(() => {
            window.location.href = "/hydrovibe2024/tickets";
          }, 1000);
        }
        // toast.success("Ticket types fetched successfully!");
      } else {
        // toast.error("Failed to fetch ticket types.");
        // console.debug("debug...");
      }
    } catch (error) {
      console.log("Error: ", error.data);
    }
  };

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   setStep(2);
  // };

  return (
    <div className="w-full flex flex-col items-center gap-4 text-formisBlack14">
      {step === 1 && (
        <form
          onSubmit={handlePhoneSubmit}
          className="w-full flex flex-col gap-4"
        >
          <input
            name="phoneNumber"
            placeholder="Phone Number"
            type="text"
            className="input"
            inputMode="numeric"
            value={phoneNumber}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d{0,10}$/.test(value)) {
                setPhoneNumber(value);
              }
            }}
            required
          />

          <div className="select w-full flex justify-between">
            <p className="text-sm text-[#545454]">Country</p>
            <p>🇮🇳 India</p>
          </div>
          <button type="submit" className="button" disabled={loading}>
            {loading ? <div className="loader mx-auto"></div> : "Next"}
          </button>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleOtpSubmit} className="w-full flex flex-col gap-4">
          <input
            name="otp"
            placeholder="Enter OTP"
            type="text"
            value={otp}
            inputMode="numeric"
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d{0,6}$/.test(value)) {
                setOtp(value);
              }
            }}
            required
            className="input"
          />
          <button type="submit" className="button">
            {loading ? <div className="loader mx-auto"></div> : "Verify OTP"}
          </button>
        </form>
      )}

      {step === 3 && (
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4">
          <input
            name="name"
            placeholder="Name"
            type="text"
            className="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <input
            name="email"
            placeholder="Email"
            type="email"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <div className="flex items-center select w-full">
            <label className="w-[50%] flex items-center text-sm text-[#545454]">
              Age
            </label>
            <select
              name="age"
              className="w-[50%] cursor-pointer bg-transparent outline-none select-placeholder"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
            >
              <option value="" disabled hidden>
                Select your age
              </option>
              <option value="18-24">18-24</option>
              <option value="25-40">25-40</option>
              <option value="41-55">41-55</option>
              <option value="55+">55+</option>
            </select>
          </div>

          <div className="flex items-center select w-full">
            <label className="flex items-center w-[50%]  text-sm text-[#545454]">
              Gender
            </label>
            <select
              name="gender"
              className="w-[50%] cursor-pointer bg-transparent outline-none select-placeholder"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="" disabled hidden>
                Select your gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>

          <div className="flex items-center select w-full">
            <label className="flex items-center w-[50%]  text-sm text-[#545454]">
              Select time slot
            </label>
            <select
              name="tentativetime"
              className="w-[50%] cursor-pointer bg-transparent outline-none select-placeholder"
              value={tentativeTime}
              onChange={(e) => setTentativeTime(e.target.value)}
              required
            >
              <option value="" disabled hidden>
                Select your slot
              </option>
              <option value="5PM-7PM">5PM to 7PM</option>
              <option value="8:30PM-11:30PM">8:30PM to 11:30PM</option>
            </select>
          </div>

          <div className="flex items-center select w-full">
            <label className="w-[50%] flex items-center text-sm text-[#545454]">
              Ticket Type
            </label>
            <select
              name="tickettype"
              className="w-[50%] cursor-pointer bg-transparent outline-none select-box"
              value={ticketType}
              onChange={(e) => setTicketType(e.target.value)}
              required
            >
              <option value="" disabled hidden>
                Select ticket type
              </option>

              {remainingTicketTypes[0]?.total_tickets_available > 0 && (
                <option value={remainingTicketTypes[0]?.id}>
                  {remainingTicketTypes[0]?.name}
                </option>
              )}
              {remainingTicketTypes[1]?.total_tickets_available > 0 && (
                <option value={remainingTicketTypes[1]?.id}>
                  {remainingTicketTypes[1]?.name}
                </option>
              )}
              {remainingTicketTypes[2]?.total_tickets_available > 0 && (
                <option value={remainingTicketTypes[2]?.id}>
                  {remainingTicketTypes[2]?.name}
                </option>
              )}
              {remainingTicketTypes[3]?.total_tickets_available > 0 && (
                <option value={remainingTicketTypes[3]?.id}>
                  {remainingTicketTypes[3]?.name}
                </option>
              )}
            </select>
          </div>

          <div className="select flex items-center justify-between">
            <div className="w-[50%] flex text-sm text-[#545454]">
              {ticketType ? (
                <span className="font-bold text-[20px]">
                  ₹{remainingTicketTypes[ticketType - 1].price * quantity}/-
                </span>
              ) : (
                <span>Quantity</span>
              )}
            </div>
            <div className="w-[50%] flex items-center justify-between">
              <button
                type="button"
                onClick={() =>
                  setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1))
                }
                className="border bg-formisBlack15 px-4 py-2 text-white rounded-[10px]"
              >
                -
              </button>

              <p>{quantity}</p>

              <button
                type="button"
                onClick={() =>
                  setQuantity((prevQuantity) => Math.min(10, prevQuantity + 1))
                }
                className="border bg-formisBlack15 px-4 py-2 text-white rounded-[10px]"
              >
                +
              </button>
            </div>
          </div>

          <button type="submit" className="button">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default RegistrationForm;