import Navbar from "../components/Main/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Accordian from "../components/Contact/Accordian";
import logo from "../assets/KITSABlack.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const Contact = () => {
  return (
    <main>
      <Navbar isLoaded={true} />

      <div
        className="pt-[80px] text-white
                  w-screen flex flex-col md:flex-row justify-between"
      >
        <section
          className="flex flex-col items-start justify-center py-20 md:h-screen md:w-[50%] 
                    px-[40px] lg:px-[60px] xl:px-[90px] bg-white text-formisBlack15 mobile-landscape:h-[150vh]"
        >
          <h2 className="text-[34px] lg:text-[58px] font-medium mb-5">
            TALK TO US
          </h2>
          <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60 mb-10">
            Hey, we are excited to connect and hear your story.
          </p>

          <div className="max-w-full">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              loop={true}
              pagination={{ clickable: true }}
              navigation={{
                nextEl: ".next-button",
                prevEl: ".prev-button",
              }}
              autoplay={{
                delay: 9000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
            >
              <SwiperSlide>
                <img src={logo} alt="KITSA™" className="max-w-[132px] mb-5" />
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60 mb-5">
                  "Great working atmosphere at{" "}
                  <span className="font-bold">FORMIS®</span>, Friendly and
                  innovative culture environment in office. Good example of team
                  work and appreciation"
                </p>

                <div className="">
                  <p>Nikhil Singh Charak</p>
                  <p className="">Chief Operating Officer</p>
                  <p className="font-bold">
                    FORMIS® Health Technologies Private Ltd.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={logo} alt="KITSA™" className="max-w-[132px] mb-5" />
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60 mb-5">
                  "I value being part of a collaborative and healthy work
                  environment alongside motivated and goal-oriented colleagues."
                </p>
                <div className="">
                  <p>Manan Batra</p>
                  <p>Business Development Unit</p>
                  <p className="font-bold">
                    FORMIS® Health Technologies Private Ltd.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={logo} alt="KITSA™" className="max-w-[132px] mb-5" />
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60 mb-5">
                  "In <span className="font-bold">FORMIS®</span>, I'm proud of our team's innovation and commitment
                  to delivering reliable, scalable solutions. We're ready for
                  future success in the evolving tech landscape."
                </p>
                <div className="">
                  <p>Sarthak Tyagi</p>
                  <p>Chief Innovation and Technologies Officer</p>
                  <p className="font-bold">
                    FORMIS® Health Technologies Private Ltd.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={logo} alt="KITSA™" className="max-w-[132px] mb-5" />
                <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60 mb-5">
                  "I've had a great experience at{" "}
                  <span className="font-bold">FORMIS®</span>. The supportive and
                  collaborative environment fosters teamwork, and I feel valued.
                  There are plenty of opportunities for personal and
                  professional growth"
                </p>
                <div className="">
                  <p>Bhanu Bansal</p>
                  <p>Frontend Engineer</p>
                  <p className="font-bold">
                    FORMIS® Health Technologies Private Ltd.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="flex gap-5 items-center justify-end mt-5">
              <a
                href="mailto:leap@kitsa.in"
                className="bg-formisBlack15 h-[47px] text-[12px] md:text-[14px] font-[500] px-4 border border-formisBlack15
                          flex items-center justify-center rounded-full text-white cursor-pointer
                          hover:bg-transparent hover:border hover:border-formisBlack15 hover:text-formisBlack15 transition-colors"
              >
                Join &nbsp;<span className="font-bold">KITSA™</span>
              </a>
              <div className="flex gap-3 items-center">
                <button className="prev-button">
                  <FaArrowLeft />
                </button>
                <button className="next-button">
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section
          className="flex flex-col xl:flex-row items-center justify-center xl:justify-end gap-10 
                      py-20 md:h-screen md:w-[50%] mobile-landscape:h-[150vh]
                      px-[40px] lg:px-[60px] xl:px-[90px] bg-[#f1edeb]"
        >
          <div className="bg-white text-formisBlack15 rounded-[10px] xl:w-[50%] px-[20px] py-[10px] hover:scale-105 transition-transform">
            <Accordian title="For Press and Events">
              <p>
                Drop us an email at{" "}
                <a href="mailto:communication@kitsa.in" className="font-[600] border-b border-formisBlack15">
                  communication@kitsa.in
                </a>
              </p>
              <p>
                or call us directly at{" "}
                <a href="tel:+91-9311960160" className="font-[600] border-b border-formisBlack15 inline-block">
                  +91 9311960160
                </a>
                ,{" "}
                <a href=" +91 124 5025 637" className="font-[600] border-b border-formisBlack15 inline-block">
                  +91 124 5025 637
                </a>
              </p>
            </Accordian>
          </div>
          <div className="bg-white text-formisBlack15 rounded-[10px] xl:w-[50%] px-[20px] py-[10px] hover:scale-105 transition-transform">
            <Accordian title="For Consumers">
              <p>
                Drop us an email at{" "}
                <a href="mailto:welisten@kitsa.in" className="font-[600] border-b border-formisBlack15">
                  welisten@kitsa.in
                </a>
              </p>
              <p>
                or call us directly at{" "}
                <a href="tel:+91-9311960160" className="font-[600] border-b border-formisBlack15 inline-block">
                  +91 9311960160
                </a>
                ,{" "}
                <a href="tel:+91 124 5025 637" className="font-[600] border-b border-formisBlack15 inline-block">
                  +91 124 5025 637
                </a>
              </p>
            </Accordian>
          </div>
        </section>
      </div>

      <Footer />
    </main>
  );
};

export default Contact;
