import redefine1 from "../../../assets/redefine/redefine1.jpeg";
import redefine2 from "../../../assets/redefine/redefine2.jpeg";
import gif1 from "../../../assets/redefine/gif1.gif";
import gif2 from "../../../assets/redefine/gif2.gif";
import { Link } from "react-router-dom";

const Redefine = () => {
  return (
    <section
      className="bg-white text-formisBlack14 
                  px-[40px] xl:px-[90px] pt-10 lg:pt-32 pb-[48px] lg:pb-20"
    >
      {/* <div className="text-4xl md:text-6xl lg:text-[5.5rem] font-medium font-[Hind] mb-10 lg:mb-20">
        <h1 className="leading-[2.5rem] md:leading-[4.5rem] lg:leading-[7rem]">
          Redefine The CDP<span className="text-formisOrange">.</span>
        </h1>
      </div> */}

      <div className="mb-[40px] lg:mb-[160px] lg:flex lg:gap-[45px]">
        <img
          src={gif1}
          alt="KITSA"
          className="lg:w-[60%] 2xl:max-w-[60%] rounded-[10px]"
        />
        <div className="pt-4">
          <h3 className="text-[20px] lg:text-[24px] xl:text-[34px] my-[24px] lg:my-0 lg:mb-[30px]">
            More Than Just Medicine Delivery
            <span className="text-formisOrange">.</span>
          </h3>

          <p className="opacity-60 text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px]">
            Let’s get one thing clear:{" "}
            {/* <span className="font-bold">KITSA™</span>{" "} */}
            <span className="inline-flex font-bold">
              KITSA
              <span className="text-[50%] align-super">™</span>{" "}
            </span>{" "}
            isn’t just about medicine delivery . We’re here for your health
            essentials , lifestyle needs , and{" "}
            <span className="font-bold">life-enhancing products</span>
            . <br /> <br /> <br />
            Think glowing skin, wellness supplements, pain relief
            aids—everything that keeps you thriving. If you’re still thinking
            about " buy medicine online " or endlessly searching for a "chemist
            open near me," you're thinking too small.
            <br />
            <br />
            <br />
            At {/* <span className="font-bold">KITSA™</span> */}
            <span className="inline-flex font-bold">
              KITSA
              <span className="text-[50%] align-super">™</span>{" "}
            </span>{" "}
            , the cure is around the corner , literally. In just 20 seconds, you
            can place an order, and in 20 minutes, it’s at your door. Powered by{" "}
            <span className="font-bold">FORMIS®</span> ,{" "}
            {/* <span className="font-bold">KITSA™</span>  */}
            <span className="inline-flex font-bold">
              KITSA
              <span className="text-[50%] align-super">™</span>{" "}
            </span>{" "}
            turns the hassle of health products into a thing of the past. No
            apps, no extra steps—
            <span className="font-bold">just WhatsApp , and you’re done.</span>
            <br /> <br /> <br />
          </p>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=919773910084&text=Hi%21+Kitsa&type=phone_number&app_absent=0"
            className="lg:mb-0 bg-formisBlack15 h-[47px] px-4 text-[12px] md:text-[14px] font-[500] mt-[24px]
                inline-flex items-center justify-center rounded-full text-white cursor-pointer border border-formisBlack15
                hover:bg-transparent hover:border-formisBlack15 hover:text-formisBlack15 transition-colors"
          >
            Order Now On WhatsApp
          </a>
        </div>
      </div>

      <div className="lg:flex flex-row-reverse lg:gap-[45px]">
        <img
          src={gif2}
          alt="Hyrdovibe"
          className="lg:max-w-[60%] 2xl:max-w-[60%] rounded-[10px]"
        />
        <div className="pt-4">
          <h3 className="text-[20px] lg:text-[24px] xl:text-[34px] my-[24px] lg:my-0 lg:mb-[24px]">
            <span className="">
              KITSA
              <span className="text-[50%] align-super">™</span>
              .LIFE – Powered by FORMIS®
            </span>{" "}
          </h3>

          <p className="opacity-60 text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px]">
            Ready to ditch the ordinary? Welcome to{" "}
            <span className="font-bold">
              KITSA
              <span className="text-[7px] lg:text-[10px] align-super">™</span>
              .LIFE
            </span>{" "}
            , powered by <span className="font-bold">FORMIS®</span>—where
            re-energizing festivals take you beyond the usual. Think{" "}
            <span className="font-bold">
              mountain treks, splash waterbed events,
            </span>{" "}
            and <span className="font-bold">aerial adventures</span>.<br />
            <br />
            <br />
            As a{" "}
            <span className="font-bold">
              KITSA
              <span className="text-[7px] lg:text-[10px] align-super">
                ™
              </span>{" "}
              member,
            </span>{" "}
            you get <span className="font-bold">free access</span> to all of it.
            Yep, even <span className="font-bold">Hydrovibe Tickets</span>
            —yours, always. Plus, score{" "}
            <span className="font-bold">exclusive deals</span> at{" "}
            <span className="font-bold">Anytime Fitness, Headlocks,</span> and{" "}
            <span className="font-bold">bBlunt</span>. <br />
            <br />
            <br />
            It’s not just an event, it’s a lifestyle upgrade. So, what are you
            waiting for? Let’s make life a little more epic.
            <br />
            <br />
            <br />
          </p>
          <Link to="/hydrovibe2024"
            className="lg:mb-0 bg-formisBlack15 h-[47px] px-4 text-[12px] md:text-[14px] font-[500] mt-[24px]
                inline-flex items-center justify-center rounded-full text-white cursor-pointer border border-formisBlack15
                hover:bg-transparent hover:border-formisBlack15 hover:text-formisBlack15 transition-colors"
          >
            Unlock KITSA
            <span className="text-[7px] lg:text-[10px] align-super">™</span>
            .LIFE
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Redefine;
